@import "~/src/scss/index.scss";

.footer-menu {
  width: 33.33%;

  > h4 {
    color: $text-light;
  }

  .ui.grid {
    height: 100%;

    .column {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      a {
        color: $text-light;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
