.courses-page {
  &__add {
    position: absolute;
    right: 0;
    top: 0;

    > .ui.button {
      margin: 0;
    }
  }

  .ui.tab.segment {
    height: calc(100vh - 200px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
