$primary: #1890ff;
$primary-hover: #0280b3;

// Text
$text-light: #fff;

// Background
$background-dark: #001b35;
$background-grey: #f0f3f4;
$background-dark-web: #16212b;

// Border
$border-grey: #808080;

// Status
$success: #84b84c;
$error: #9f3a38;

// Social Color
$youtube: #cd201f;
$twitter: #1da1f2;
$facebook: #3b5998;
$linkedin: #0077b5;
