.blog-page {
  &__add {
    display: flex;
    justify-content: flex-end;

    > .ui.button {
      margin: 0;
    }
  }

  .ui.menu.secondary {
    min-height: 0px;
  }
}
